import { Box, IconButton } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { ImageText } from '../ImageTextField'
import { WtxColors } from '@wavetronix/common-components'
import { useEffect, useState } from 'react'

export default function MultipleChoiceQuestion({ question, guid, setAnswer, showPoints }) {
  const [rerender, setRerender] = useState(false)
  useEffect(() => {
    if (rerender) {
    }
  }, [rerender])

  const selectAnswer = key => {
    setAnswer(guid, key)
    setRerender(!rerender)
  }

  return (
    <>
      <div style={{ padding: '10px' }}>
        <ImageText
          image={question.imageInfo ? question.imageInfo.image : null}
          text={question.prompt}
          isHeading={true}
          position={question.imageInfo ? question.imageInfo.position : null}
          imageComp={{ type: 'display' }}
        />
        {question.answerData
          ? Object.entries(question.answerData).map(([key, answer], index) => {
              return (
                <Box key={index} sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      margin: '15px'
                    }}
                  >
                    <IconButton
                      id={`${guid}AnswerSelectButton`}
                      sx={{ marginRight: '15px' }}
                      onClick={e => {
                        selectAnswer(key)
                      }}
                    >
                      {answer.isCorrect ? <CheckBoxIcon sx={{ color: WtxColors.GROWTH_GREEN }} /> : <CheckBoxOutlineBlankIcon />}
                    </IconButton>
                    <ImageText
                      id={`${guid}AnswerTextField`}
                      image={answer.imageInfo ? answer.imageInfo.image : null}
                      text={answer.answer}
                      position={answer.imageInfo ? answer.imageInfo.position : null}
                      imageComp={{ type: 'display' }}
                    />
                  </div>
                </Box>
              )
            })
          : null}
      </div>
    </>
  )
}
