import TakeExamView from './TakeExamView'
import ExamsApi from '../api/ExamsApi'
import GatekeeperApi from '../api/GatekeeperApi.js'
import { useMsal } from '@azure/msal-react'
import { v4 } from 'uuid'
import { useEffect, useState } from 'react'
import queryString from 'query-string'
import { env } from '../index.js'

export default function ExamPage({ setState }) {
  const { instance, accounts } = useMsal()

  const [exam, setExam] = useState({})
  const [questionsMap, setQuestionsMap] = useState({})
  const [isLockedOut, setIsLockedOut] = useState(false)
  const [loading, setLoading] = useState(true)

  let urlObj = queryString.parseUrl(window.location.href)
  let examID = urlObj.query.id
  let issuerID = urlObj.query.issuer

  useEffect(() => {
    const getLockout = async () => {
      const temp = await ExamsApi.getExam(instance, accounts, examID)
      const user = await GatekeeperApi.getMe(instance, accounts)
      const record = await ExamsApi.getUserExamRecord(instance, accounts, user.id, examID)
      if (record ? record.length > 0 : false) {
        setIsLockedOut(record[0].attemptsRemaining <= 0)
      } else setIsLockedOut(false)
      setExam(temp)
    }

    if (examID && examID !== '') {
      getLockout()
    }
  }, [instance, accounts, examID])

  useEffect(() => {
    setLoading(false)
  }, [isLockedOut])

  useEffect(() => {
    let tempMap = {}
    if (exam && exam.questions) {
      for (let question of exam.questions) {
        let guid = v4()
        tempMap[guid] = {
          number: question.number,
          type: question.type,
          prompt: question.prompt,
          imageInfo: question.imageInfo,
          pointValue: question.point
        }
        switch (question.type) {
          case 'Freeform':
            tempMap[guid] = { ...tempMap[guid], answerData: question.freeResponseAnswer }
            break
          case 'Matching':
            for (let match of question.matches) {
              let qguid = v4()
              tempMap[guid] = { ...tempMap[guid], answerData: { ...tempMap[guid].answerData, [qguid]: match } }
            }
            break
          case 'Multiple Choice':
            for (let answer of question.multipleChoiceAnswers) {
              let qguid = v4()
              tempMap[guid] = { ...tempMap[guid], answerData: { ...tempMap[guid].answerData, [qguid]: answer } }
            }
            break
          default:
            break
        }
      }
    }
    setQuestionsMap(tempMap)
  }, [exam, examID])

  if (loading) {
    return <>loading</>
  } else if (isLockedOut) {
    return <h3>You have no attempts remaining for this exam! Please contact your Wavetronix technical trainer.</h3>
  } else {
    return (
      <>
        {examID ? (
          !isLockedOut ? (
            Object.keys(questionsMap).length > 0 ? (
              <TakeExamView
                onChangeCallback={id => setState(s => ({ ...s, current: id }))}
                examId={exam.id}
                examName={exam.name}
                issuerId={issuerID}
                questionsMap={questionsMap}
                examImageInfo={exam.imageInfo}
                originalExam={exam}
              />
            ) : (
              <></>
            )
          ) : (
            <h3>You have no attempts remaining for this exam! Please contact your Wavetronix technical trainer.</h3>
          )
        ) : (
          <>
            <h3>No exam ID was provided. Please use a link from a Wavetronix technical trainer to access your exam.</h3>

            {env.runMode === 'dev' ? (
              <a href='/?id=92b99b2a-818d-4bf7-9a12-ebd2b464a908&issuer=1c50a8b7-5808-4b28-b87e-a7b77817c1d0'>
                click here for test exam
              </a>
            ) : (
              <></>
            )}
          </>
        )}
      </>
    )
  }
}
