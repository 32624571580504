import { Card, Grid, Stack, DialogContent } from '@mui/material'
import { CenteredDiv, PrimaryButton, RegularButton, SuccessButton, getMe, SlideUpDialog } from '@wavetronix/common-components'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FreeformQuestion from './InteractiveQuestions/FreeformQuestion'
import MatchingQuestion from './InteractiveQuestions/MatchingQuestion'
import MultipleChoiceQuestion from './InteractiveQuestions/MultipleChoiceQuestion'
import ExamsApi from '../api/ExamsApi'
import { env } from '../index'
import { useMsal } from '@azure/msal-react'
import GatekeeperApi from '../api/GatekeeperApi'
import { ImageText } from './ImageTextField'
import { useCrossTabState } from '../customhooks/useCrossTabState'

// const DEFAULT_ANSWERS = [{ question: { answer: '', id: 0, type: '', prompt: '' } }]

export default function PreviewExamView({ originalExam, questionsMap, examId, issuerId, examName, examImageInfo, ...props }) {
  const [rightChoices, setRightChoices] = useState()
  const tempQuestions = useMemo(() => {
    let qResult = {}
    Object.keys(questionsMap).map(qGuid => {
      let aResult = {}
      Object.keys(questionsMap[qGuid].answerData).map(aGuid => {
        aResult[aGuid] = { ...questionsMap[qGuid].answerData[aGuid], isCorrect: false }
        return true
      })
      qResult[qGuid] = { ...questionsMap[qGuid], answerData: aResult }
      return true
    })
    return qResult
  }, [questionsMap])
  const [userAnswers, setUserAnswers] = useCrossTabState(originalExam.id, tempQuestions)
  const navigate = useNavigate()
  const { instance, accounts } = useMsal()
  const [submitModal, setSubmitModal] = useState(false)

  function updateAnswerPreview(tempQuestions) {
    for (let guid of Object.keys(userAnswers)) {
      for (let i = 0; i < userAnswers[guid].answerData.length; i++) {
        if (userAnswers[guid].answerData[i].isCorrect) {
          tempQuestions[guid].answerData[i].isCorrect = true
        } else {
          tempQuestions[guid].answerData[i].isCorrect = false
        }
      }
    }
  }

  function makeSubmission(answers, pointsEarned) {
    let pointsPossible = originalExam.questions.reduce((acc, curr) => acc + curr.point, 0)
    let result = []
    for (let answer of answers) {
      result.push({ multipleChoiceAnswers: answer.answerData, ...answer })
    }
    return {
      answers: result,
      grade: {
        percentage: isNaN(Math.round((pointsEarned / pointsPossible) * 100))
          ? 0
          : Math.round((pointsEarned / pointsPossible) * 100)
      }
    }
  }

  async function submit() {
    let user = await getMe(instance, env, accounts)
    let record = await ExamsApi.getUserExamRecord(instance, accounts, user.id, examId)
    let issuer = await GatekeeperApi.getUser(issuerId)

    let pointsEarned = 0
    let answers = []
    console.log(Object.values(userAnswers))
    for (let answer of Object.values(userAnswers)) {
      answer.answerData = Object.values(answer.answerData)
      answers.push(answer)
    }
    for (let question of originalExam.questions) {
      switch (question.type) {
        case 'Multiple Choice':
          let correctSoFar = true
          let userChoices = answers.filter(answer => answer.number === question.number)[0].answerData
          let correctChoices = question.multipleChoiceAnswers
          for (let choice of Object.keys(userChoices)) {
            let correctChoice = correctChoices.filter(c => c.answer === userChoices[choice].answer)[0]
            if (userChoices[choice].isCorrect !== correctChoice.isCorrect) {
              correctSoFar = false
            }
          }
          if (correctSoFar) {
            pointsEarned += question.point
            answers[answers.findIndex(e => e.number === question.number)] = {
              point: question.point,
              ...answers[answers.findIndex(e => e.number === question.number)]
            }
          }
          break
        default:
          break
      }
      localStorage.removeItem(`${originalExam.id}`)
    }

    let submission = makeSubmission(answers, pointsEarned)

    if (record.length === 0) {
      await ExamsApi.submitExam(instance, accounts, '', {
        attemptsRemaining: 2,
        submissions: [submission],
        examId: examId,
        userId: user.id,
        examName: examName,
        notified: true,
        issuerEmail: issuer.email
      }).finally(() => {
        navigate(`/submitted?id=${examId}&issuer=${issuerId}`)
      })
    } else {
      record = record[0]
      let newRecord = {
        attemptsRemaining: record.attemptsRemaining - 1,
        submissions: [submission, ...record.submissions],
        userId: user.id,
        examId: examId,
        id: record.id,
        examName: examName,
        notified: record.notified,
        issuerEmail: issuer.email
      }
      await ExamsApi.submitExam(instance, accounts, `/${record.id}`, newRecord).finally(() => {
        navigate(`/submitted?id=${examId}&issuer=${issuerId}`)
      })
    }
  }

  useEffect(() => {
    for (let guid of Object.keys(userAnswers)) {
      if (tempQuestions[guid] === undefined) {
        tempQuestions[guid] = userAnswers[guid]
      }
    }
  }, [userAnswers, tempQuestions])

  const previewAnswer = (guid, key) => {
    setUserAnswers(u => ({ ...u, [guid]: tempQuestions[guid] }))
    updateAnswerPreview(tempQuestions)
    tempQuestions[guid].answerData[key].isCorrect = !tempQuestions[guid].answerData[key].isCorrect
  }

  const setAnswer = (QuestionKey, answer) => {
    let answersMap = Object.entries(userAnswers).map(([key, data]) => {
      if (QuestionKey === key) {
        if (data.type === 'Freeform') {
          data.answerData = answer
        } else if (data.type === 'Matching') {
        } else if (data.type === 'Multiple Choice') {
          data.answerData = Object.entries(data.answerData).map(([key, option]) =>
            key === answer ? (option = { ...option, isCorrect: !option.isCorrect }) : (option = { ...option })
          )
        }
      }
      return userAnswers
    })
    setUserAnswers(answersMap[0])
  }

  return (
    <>
      <SlideUpDialog
        id='submitModal'
        open={submitModal}
        title='Submit Exam?'
        actions={
          <>
            <PrimaryButton onClick={submit}>Yes, submit now.</PrimaryButton>
            <RegularButton
              onClick={() => {
                setSubmitModal(false)
              }}
            >
              No, don't submit.
            </RegularButton>
          </>
        }
        onClose={() => {
          setSubmitModal(false)
        }}
      >
        <DialogContent>
          <h3>Are you sure you want to submit?</h3>
          <h3>Double check that you have answered every question.</h3>
        </DialogContent>
      </SlideUpDialog>
      <div style={{ margin: '20px' }}>
        <ImageText
          image={examImageInfo ? examImageInfo.image : null}
          text={examName}
          isHeading={true}
          position={examImageInfo ? examImageInfo.position : null}
          imageComp={{ type: 'display' }}
        />

        <Stack sx={{ width: '100%', marginBottom: '20px' }}>
          {userAnswers
            ? Object.entries(userAnswers)
                .sort(([aKey, aQuestion], [bKey, bQuestion]) => {
                  if (aQuestion.number < bQuestion.number) {
                    return -1
                  } else {
                    return 1
                  }
                })
                .map(([key, question], index) => {
                  let leftOptions = []

                  if (question.type === 'Matching') {
                    leftOptions = Object.entries(question.answerData).map(([key, ans], index) => ({
                      key: key,
                      answer: { leftImageInfo: ans.leftImageInfo, leftMatch: ans.leftMatch }
                    }))
                  }

                  return (
                    <div key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                      <Card sx={{ width: '80%', minHeight: '225px', marginTop: '15px' }}>
                        <Grid container sx={{ height: '100%' }}>
                          <Grid item xs={1.5} lg={0.5}></Grid>
                          <Grid item xs={10.5} lg={11.5}>
                            {originalExam.isCustomPointValue ? (
                              <h3 style={{ marginLeft: '10px' }}>{question.pointValue} points</h3>
                            ) : (
                              <></>
                            )}
                          </Grid>
                          <Grid item xs={1.5} lg={0.5}>
                            <div
                              style={{
                                textAlign: 'right',
                                margin: '10px'
                              }}
                            >
                              <span style={{ fontSize: '1.17em' }}>Q{question.number}</span>
                            </div>
                          </Grid>
                          <Grid item lg={11}>
                            {question.type === 'Freeform' ? (
                              <FreeformQuestion setAnswer={setAnswer} question={question} answerKey={key} />
                            ) : question.type === 'Multiple Choice' ? (
                              <MultipleChoiceQuestion
                                setAnswer={previewAnswer}
                                question={question}
                                guid={key}
                                showPoints={originalExam.isCustomPointValue}
                              />
                            ) : question.type === 'Matching' ? (
                              <MatchingQuestion
                                prompt={question.prompt}
                                imageInfo={question.imageInfo}
                                setAnswer={setAnswer}
                                leftOptions={leftOptions}
                                rightOptions={rightChoices}
                                setRightOptions={setRightChoices}
                                index={index}
                                answerKey={key}
                              />
                            ) : (
                              <div>Error: unknown question type</div>
                            )}
                          </Grid>
                        </Grid>
                      </Card>
                    </div>
                  )
                })
            : null}
        </Stack>
        <CenteredDiv>
          <SuccessButton
            id='examSubmitButton'
            onClick={() => {
              setSubmitModal(true)
            }}
          >
            Submit
          </SuccessButton>
        </CenteredDiv>
      </div>
    </>
  )
}
