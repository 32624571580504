import { Card, Grid, Stack } from '@mui/material'
import { CenteredDiv, Spinner } from '@wavetronix/common-components'
import FreeformQuestion from './InteractiveQuestions/FreeformQuestion'
import MatchingQuestion from './InteractiveQuestions/MatchingQuestion'
import ExamsApi from '../api/ExamsApi'
import GatekeeperApi from '../api/GatekeeperApi'
import { useMsal } from '@azure/msal-react'
import { useEffect, useState, useCallback, useMemo } from 'react'
import { v4 } from 'uuid'
import MultipleChoiceQuestionReview from './StaticQuestions/MultipleChoiceQuestionReview'

export default function ExamReview({
  originalExam,
  questionsMap,
  examId,
  issuerId,
  examName,
  examImageInfo,
  retakeSlug,
  ...props
}) {
  const { instance, accounts } = useMsal()
  const [record, setRecord] = useState([])
  const [exam, setExam] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isConfigured, setIsConfigured] = useState(false)

  const tempQuestions = useMemo(() => ({}), [])
  const examQuestions = useMemo(() => ({}), [])

  const loadRecord = useCallback(
    async function () {
      let user = await GatekeeperApi.getMe(instance, accounts)
      let data = await ExamsApi.getUserExamRecord(instance, accounts, user.id, examId)

      let result = {}
      if (data && data.length > 0) {
        let currentSubmission = data[0].submissions.sort((a, b) => {
          return new Date(b.date) - new Date(a.date)
        })[0]
        result = currentSubmission.answers
      }
      setRecord(result)
      return result
    },
    [instance, accounts, examId]
  )

  const loadExam = useCallback(
    async function () {
      let data = await ExamsApi.getExam(instance, accounts, examId)

      let result = {}
      if (data && data.questions) {
        result = data.questions
      }
      setExam(result)
      return result
    },
    [instance, accounts, examId]
  )

  useEffect(() => {
    if (!isLoading) {
      let success = false
      do {
        success = configureQuestions()
      } while (success === false)
      setIsConfigured(true)
    }
  }, [isLoading])

  useEffect(() => {
    if (record.length > 0 && exam.length > 0) {
      setIsLoading(false)
    }
  }, [exam, record.length])
  useEffect(() => {
    if (record.length > 0 && exam.length > 0) {
      setIsLoading(false)
    }
  }, [record, exam.length])

  useEffect(() => {
    loadExam()
    loadRecord()
  }, [instance, accounts, loadRecord, loadExam])

  const configureQuestions = function () {
    if (Object.keys(tempQuestions).length != record.length) {
      for (let i = 0; i < record.length; i++) {
        let guid = v4()
        tempQuestions[guid] = {
          answerData: record[i].multipleChoiceAnswers.map(data => ({ isCorrect: data.isCorrect, ...data })),
          pointValue: record[i].point,
          ...record[i]
        }
        if (exam[i]) {
          examQuestions[guid] = {
            answerData: exam[i].multipleChoiceAnswers.map(data => ({ isCorrect: data.isCorrect, ...data })),
            pointValue: exam[i].point,
            ...exam[i]
          }
        }
      }
      for (let question of Object.entries(tempQuestions)) {
        if (examQuestions[question[0]]) {
          for (let i = 0; i < question[1].answerData.length; i++) {
            question[1].answerData[i].isUserAnswer = question[1].answerData[i].isCorrect
            question[1].answerData[i].isCorrect =
              question[1].answerData[i].isCorrect === examQuestions[question[0]].answerData[i].isCorrect
          }
        }
      }
      return true
    }
    return false
  }

  return !isConfigured ? (
    <Spinner></Spinner>
  ) : (
    <div style={{ margin: '20px' }}>
      <CenteredDiv>
        <h2>{examName}</h2>
      </CenteredDiv>

      <Stack sx={{ width: '100%', marginBottom: '20px' }}>
        {tempQuestions
          ? Object.entries(tempQuestions)
              .sort(([aKey, aQuestion], [bKey, bQuestion]) => {
                if (aQuestion.number < bQuestion.number) {
                  return -1
                } else {
                  return 1
                }
              })
              .map(([key, question], index) => {
                return (
                  <CenteredDiv key={index}>
                    <div style={{ textAlign: 'center', marginRight: '10px' }}>Q{question.number}</div>

                    <Card sx={{ width: '80%', minHeight: '225px', marginTop: '15px' }}>
                      <Grid container>
                        <Grid item lg={12}>
                          {question.type === 'Freeform' ? (
                            <FreeformQuestion question={question} answerKey={key} />
                          ) : question.type === 'Multiple Choice' ? (
                            <MultipleChoiceQuestionReview question={question} guid={key} />
                          ) : question.type === 'Matching' ? (
                            <MatchingQuestion
                              prompt={question.prompt}
                              imageInfo={question.imageInfo}
                              index={index}
                              answerKey={key}
                            />
                          ) : (
                            <div>Error: unknown question type</div>
                          )}
                        </Grid>
                      </Grid>
                    </Card>
                  </CenteredDiv>
                )
              })
          : null}
      </Stack>
    </div>
  )
}
